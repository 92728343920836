import { AfterViewInit, Component, OnDestroy } from "@angular/core";

import { Subscription } from "rxjs";

import { IframeService } from "../iframe.service";

@Component({
    selector: "fumanbrush-iframe",
    styleUrls: ["./iframe.component.scss"],
    templateUrl: "./iframe.component.html",
})
export class IframeComponent implements AfterViewInit, OnDestroy {
    // list on user interaction
    private readonly subscriptions = new Subscription();

    public constructor(public readonly iframeService: IframeService) {}

    public ngAfterViewInit() {
        this.subscriptions.add(
            this.iframeService.iframe$.subscribe((iframe: HTMLIFrameElement) => {
                const frameContainer = document.getElementById("iframe");
                if (frameContainer != null) {
                    frameContainer.appendChild(iframe);
                }
            }),
        );
    }

    public ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
