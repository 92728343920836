import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SharedModule } from "@shared/shared.module";
import { PopupComponent } from "./popup/popup.component";

@NgModule({
    declarations: [PopupComponent],
    exports: [PopupComponent],
    imports: [CommonModule, SharedModule],
})
export class PopupModule {}
