import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

import { IframeService } from "@core/iframe/iframe.service";
import { MenuService } from "@core/menu/menu.service";
import { PopupService } from "@core/popup/popup.service";

@Component({
    selector: "fumanbrush-menu",
    styleUrls: ["./menu.component.scss"],
    templateUrl: "./menu.component.html",
})
export class MenuComponent implements OnDestroy {
    private readonly subscriptions = new Subscription();

    public constructor(
        public readonly iframeService: IframeService,
        public readonly menuService: MenuService,
        private readonly popupService: PopupService,
    ) {
        this.subscriptions.add(
            this.popupService.popupAction$.subscribe((choice: boolean) => {
                if (choice) {
                    this.menuService.goToHome();
                }
            }),
        );
    }

    public displayOqp() {
        this.menuService.showForOqp();
    }

    public displayGame() {
        this.menuService.showForGame();
    }

    public displayVideo() {
        this.menuService.showForVideo();
    }

    public goToBack() {
        if (this.iframeService.isShown()) {
            this.iframeService.hide();
        } else {
            this.popupService.show();
        }
    }

    public ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
