import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { IGameGetDto } from "@dtos";
import { environment } from "@environment";
import { IGame } from "@models";
import { GameMapper } from "./game.mapper";

@Injectable()
export class GameRepository {
    public constructor(
        private readonly http: HttpClient,
        private readonly gameMapper: GameMapper,
    ) {}

    // get all games
    public getGames(): Observable<IGame[]> {
        const dataSource = this.http.get<IGameGetDto[]>(`${environment.moustacheApiUrl}/games`);

        return dataSource.pipe(
            map((games) => games.map(this.gameMapper.mapGetGame)),
            catchError(() => of([])),
        );
    }
}
