<ng-container *ngIf="popupService.display$ | async as state">
    <div class="popup" [ngClass]="state ? 'popup--show' : 'popup--hide'">
        <div class="popup-content">
            <span class="title">Vous êtes sur le point de revenir à la page d’accueil</span>
            <div class="buttons">
                <div class="button back" (click)="confirmAction()">REVENIR À L’ACCUEIL</div>
                <div class="button continue" (click)="cancelAction()">CONTINUER MON EXPLORATION</div>
            </div>
        </div>
    </div>
</ng-container>
