import { EnvironmentType, IEnvironment } from "./environment.interface";

export const environment: IEnvironment = {
    gameServerUrl: "https://testing.games.moustache.marcovasco.fr/#/",
    inatictivityTime: 3,
    mapServerUrl: "https://testing.maps.moustache.marcovasco.fr",
    mediaAssetsPath: "media",
    mediaAssetsPathOptimized: "webp/videos",
    moustacheApiUrl: "https://testing.api.moustache.marcovasco.fr",
    production: false,
    siteAssetsUrl: "https://c0.marcovasco.fr",
    streamingVideoPath: "streaming",
    type: EnvironmentType.TESTING,
};
