import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription, timer } from "rxjs";

import { environment } from "@environment";

import { IdleService } from "../idle/idle.service";

import { IVideo } from "@models/video/video";

import videojs from "video.js";

export const playerOptions = {
    autoplay: false,
    controls: false,
    fluid: true,
    muted: false,
    play: true,
    preload: "none",
    responsive: false,
    sources: [],
};

const DEFAULT_VOLUME = 0.5;
const VOLUME_STEP = 0.05;

const BTN_STATE = {
    PAUSE: "pause",
    PLAY: "play",
};

@Injectable({ providedIn: "root" })
export class PlayerService implements OnDestroy {
    public player: videojs.Player;

    public playerState = false;

    public currentVideo$: Observable<IVideo | null>;
    public relatedVideo$: Observable<IVideo[]>;

    public fullscreen = false;

    public currentDurationTime$: Observable<string>;
    public currentRemainingTime$: Observable<string>;
    public currentProgressBar$: Observable<number>;
    public currentVolumeBar$: Observable<number>;

    public playPauseBtnTitle = BTN_STATE.PLAY;

    public playerDisplay$: Observable<boolean>;

    public hideCommand$: Observable<boolean>;
    public showDetails$: Observable<boolean>;

    private readonly currentVideoSource = new BehaviorSubject<IVideo | null>(null);
    private readonly currentDurationTimeSource = new BehaviorSubject<string>("0:00");
    private readonly currentRemainingTimeSource = new BehaviorSubject<string>("0:00");
    private readonly currentProgressBarSource = new BehaviorSubject<number>(0);
    private readonly currentVolumeBarSource = new BehaviorSubject<number>(DEFAULT_VOLUME);
    private readonly hideCommandSource = new BehaviorSubject<boolean>(false);
    private readonly showDetailsSource = new BehaviorSubject<boolean>(false);

    private relatedVideosArray: IVideo[];
    private readonly relatedVideoSource = new BehaviorSubject<IVideo[]>([]);
    private readonly mediaPath = `${environment.moustacheApiUrl}/${environment.streamingVideoPath}/`;
    private readonly playerDisplaySource = new BehaviorSubject<boolean>(false);

    private playlistSize: number = 0;
    private playlistPosition: number = 0;

    private readonly commandsHidePeriod = 8000;

    private readonly subscriptions = new Subscription();

    public constructor(private readonly idleService: IdleService) {
        this.playerDisplay$ = this.playerDisplaySource.asObservable();

        this.currentVideo$ = this.currentVideoSource.asObservable();
        this.currentDurationTime$ = this.currentDurationTimeSource.asObservable();
        this.currentRemainingTime$ = this.currentRemainingTimeSource.asObservable();
        this.currentProgressBar$ = this.currentProgressBarSource.asObservable();
        this.currentVolumeBar$ = this.currentVolumeBarSource.asObservable();
        this.hideCommand$ = this.hideCommandSource.asObservable();
        this.showDetails$ = this.showDetailsSource.asObservable();
        this.relatedVideo$ = this.relatedVideoSource.asObservable();
        this.relatedVideo$.subscribe((rVideos) => (this.relatedVideosArray = rVideos));

        this.idleService.shouldClear$.subscribe((clear: boolean) => {
            // if clear is true that means toothbrush is awake from screensaver so hide player
            if (clear) {
                this.hide();
            }
        });
    }

    public initPlayer() {
        this.setCurrentVideo(null);
        this.playerState = false;
        this.currentVolumeBarSource.next(DEFAULT_VOLUME);
        this.playPauseBtnTitle = BTN_STATE.PLAY;
        this.idleService.setPause(false);
    }

    public decreaseVolume() {
        if (this.getVolume() - VOLUME_STEP >= 0) {
            this.setVolume(this.getVolume() - VOLUME_STEP);
        }
    }

    public increaseVolume() {
        if (this.getVolume() <= 1) {
            this.setVolume(this.getVolume() + VOLUME_STEP);
        }
    }

    public getVolume() {
        return this.currentVolumeBarSource.getValue();
    }

    public setVolume(volume: number) {
        this.currentVolumeBarSource.next(volume);
        this.player.volume(volume);
    }

    public toggleFullscreen() {
        this.fullscreen = !this.fullscreen;
    }

    public toggleDetails() {
        const details = !this.showDetailsSource.getValue();
        details ? this.pauseVideo() : this.playVideo();
        this.showDetailsSource.next(details);
    }

    public playEnded() {
        // check if there is a next video
        if (this.playlistPosition < this.playlistSize - 1) {
            this.playNext();
        } else {
            this.initPlayer();
        }
    }

    public playPrevious() {
        if (this.playlistPosition - 1 < 0) {
            this.playlistPosition = this.playlistSize;
        }

        this.playlistPosition--;
        const nextVideo = this.relatedVideosArray[this.playlistPosition];
        if (nextVideo != null) {
            this.changePlay(nextVideo);
        }
    }

    public playNext() {
        if (this.playlistPosition + 1 >= this.playlistSize) {
            this.playlistPosition = -1;
        }

        this.playlistPosition++;
        const nextVideo = this.relatedVideosArray[this.playlistPosition];
        if (nextVideo != null) {
            this.changePlay(nextVideo);
        }
    }

    public autoHideCommands() {
        this.hideCommandSource.next(false);
        timer(this.commandsHidePeriod)
            .pipe()
            .toPromise()
            .then(() => {
                if (this.playerState) {
                    this.hideCommandSource.next(true);
                }
            })
            .catch((e: any) => console.log(e));
    }

    public playVideo() {
        this.playerState = true;
        this.playPauseBtnTitle = BTN_STATE.PAUSE;
        this.player.play();
        this.player.volume(this.getVolume());
        this.idleService.setPause(true);
        this.autoHideCommands();
    }

    public pauseVideo() {
        this.playerState = false;
        this.playPauseBtnTitle = BTN_STATE.PLAY;
        this.player.pause();
        this.idleService.setPause(false);
        this.hideCommandSource.next(false);
    }

    public changePlay(video: IVideo) {
        this.pauseVideo();
        this.setCurrentDurationTime(0);
        this.setCurrentRemainingTime(0, 0);
        this.setCurrentVideo(video);
        this.player.src({ type: "video/mp4", src: `${this.mediaPath}${video.url}` });
        const posPlaylist = this.relatedVideosArray.findIndex((rVideo: IVideo) => rVideo.id === video.id);
        this.playlistPosition = posPlaylist;

        // manual let service play the video
        this.playVideo();
    }

    public setCurrentVideo(video: IVideo | null) {
        this.currentVideoSource.next(video);
    }

    public setCurrentDurationTime(time: number) {
        this.currentDurationTimeSource.next(this.fancyTimeFormat(time));
    }

    public setCurrentRemainingTime(rTime: number, dTime: number) {
        this.currentRemainingTimeSource.next(this.fancyTimeFormat(rTime));
        this.currentProgressBarSource.next((rTime * 100) / dTime);
    }

    public setProgressBarTo(position: number) {
        const newPosition = (position * this.player.duration()) / 100;

        this.player.pause();
        this.player.currentTime(newPosition);
        // only play if was playing
        if (this.playPauseBtnTitle === BTN_STATE.PAUSE) {
            this.player.play();
        }
    }

    public setRelatedVideos(videos: IVideo[]) {
        this.relatedVideoSource.next([...videos]);
        this.playlistSize = videos.length;
    }

    public show(video: IVideo, relatedVideos: IVideo[]) {
        /* tslint:disable */
        // this action is needed to call the good context in player callback
        const service = this;
        /* tslint:enable */

        this.setCurrentVideo(video);
        this.setRelatedVideos(relatedVideos);
        this.playerDisplaySource.next(true);
        // wait a second for DOM change
        setTimeout(() => {
            // instantiate Video.js
            this.player = videojs(document.getElementById("mainvideo"), playerOptions);
            this.player.src({ type: "video/mp4", src: `${this.mediaPath}${video.url}` });
            this.player.ready(() => {
                // manual let service play the video
                service.playVideo();
                // when it's finish reset player button state
                service.player.on("ended", function () {
                    service.playEnded();
                });

                service.player.on("loadedmetadata", function () {
                    service.setCurrentDurationTime(service.player.duration());
                });

                service.player.on("timeupdate", function () {
                    service.setCurrentRemainingTime(service.player.currentTime(), service.player.duration());
                });

                service.player.on("touchstart", function () {
                    service.autoHideCommands();
                });
            });
        }, 100);
    }

    public hide() {
        // just in case stop video and restart screensaver
        this.idleService.setPause(false);

        // hide the modal
        this.playerDisplaySource.next(false);

        // hide details
        this.showDetailsSource.next(false);

        // destroy player
        if (this.player != null) {
            this.player.dispose();
        }
    }

    public ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    protected fancyTimeFormat(duration: number) {
        // Hours, minutes and seconds
        // const hrs = Math.floor(duration / 3600);
        const mins = Math.floor((duration % 3600) / 60);
        const secs = Math.floor(duration % 60);

        // if (hrs > 0) {
        //     ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        // }

        return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
    }
}
