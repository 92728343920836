<div *ngIf="type === 'classic'" class="button">
    <span>{{ title }}</span>
</div>

<div *ngIf="type === 'plain'" class="button plain-button">
    <span>{{ title }}</span>
</div>

<div *ngIf="type === 'back'" class="back-button">
    <div class="circle"></div>
    <span>{{ title }}</span>
</div>

<div *ngIf="type === 'playgame'" class="play-game-button">
    <span>Jouer</span>
</div>

<div *ngIf="type === 'discover'" class="discover-button">
    <span>Découvrir</span>
</div>

<div *ngIf="type === 'play'" class="button play-button">
    <i class=""></i>
</div>
<div *ngIf="type === 'pause'" class="button pause-button">
    <div></div>
</div>
<div *ngIf="type === 'prev'" class="button track-prev-button">
    <i class=""></i>
</div>
<div *ngIf="type === 'next'" class="button track-next-button">
    <i class=""></i>
</div>

<div *ngIf="type === 'sound-min'" class="button sound-min">
    <i class=""></i>
</div>
<div *ngIf="type === 'sound-max'" class="button sound-max">
    <i class=""></i>
</div>

<div *ngIf="type === 'fullscreen'" class="fullscreen">
    <div class="top left"></div>
    <div class="bottom left"></div>
    <div class="top right"></div>
    <div class="bottom right"></div>
</div>
