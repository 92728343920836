<ng-container *ngIf="playerService.playerDisplay$ | async as state">
    <div
        class="player"
        [ngClass]="{
            'player--show': state,
            'player--hide': !state,
            'fullscreen--on': playerService.fullscreen,
            'fullscreen--off': !playerService.fullscreen
        }"
    >
        <div class="player__content__main">
            <div class="player__content__main--left">
                <div class="controller">
                    <fumanbrush-button type="back" title="Retour" (click)="close()"></fumanbrush-button>
                </div>
                <div class="playlist">
                    <ng-container *ngIf="playerService.relatedVideo$ | async as videos">
                        <div class="playlist--item" *ngFor="let video of videos; trackBy: trackById">
                            <fumanbrush-video
                                [item]="video"
                                [current$]="playerService.currentVideo$"
                                size="small"
                                (click)="playVideo(video)"
                            >
                            </fumanbrush-video>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="player__content__main--right">
                <fumanbrush-player-details></fumanbrush-player-details>
                <video id="mainvideo" class="video-js"></video>
                <div [ngClass]="{ hide: (playerService.hideCommand$ | async) === true }" class="played__video--volume">
                    <div
                        *ngIf="playerService.currentVolumeBar$ | async as progress"
                        class="progression"
                        [ngStyle]="{ height: progress * 100 + '%' }"
                    ></div>
                </div>
                <div class="played__video--command">
                    <div
                        [ngClass]="{ hide: (playerService.hideCommand$ | async) === true }"
                        class="played__video--fullscreen"
                    >
                        <fumanbrush-button
                            type="fullscreen"
                            (click)="toggleFullscreen()"
                            [ngClass]="{
                                'fullscreen--on': playerService.fullscreen,
                                'fullscreen--off': !playerService.fullscreen
                            }"
                        ></fumanbrush-button>
                    </div>
                    <div [ngClass]="{ hide: (playerService.hideCommand$ | async) === true }" class="buttons">
                        <div class="buttons--container">
                            <fumanbrush-button type="sound-min" (click)="decreaseVolume()"></fumanbrush-button>
                            <fumanbrush-button type="prev" (click)="playPrev()"></fumanbrush-button>
                            <fumanbrush-button
                                type="{{ playerService.playPauseBtnTitle }}"
                                (click)="togglePlay()"
                            ></fumanbrush-button>
                            <fumanbrush-button type="next" (click)="playNext()"></fumanbrush-button>
                            <fumanbrush-button type="sound-max" (click)="increaseVolume()"></fumanbrush-button>
                        </div>
                    </div>
                    <div
                        *ngIf="playerService.currentProgressBar$ | async as progress"
                        class="progression"
                        (click)="seekTo($event)"
                    >
                        <div class="progression--line" [ngStyle]="{ width: progress + '%' }"></div>
                    </div>
                    <div class="progression--time">
                        <div
                            *ngIf="playerService.currentRemainingTime$ | async as rTime"
                            class="progression--time__remaining"
                        >
                            {{ rTime }}
                        </div>
                        <div
                            *ngIf="playerService.currentDurationTime$ | async as dTime"
                            class="progression--time__duration"
                        >
                            {{ dTime }}
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="currentVideo != null">
                    <div class="played__video--title">
                        {{ currentVideo.title }}
                        <div class="played__video--info">
                            <span class="mv-icons--simple--info_circle" (click)="toggleDetails()"></span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
