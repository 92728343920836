import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { ITag } from "./tag";

@Injectable({
    providedIn: "root",
})
export class TagService {
    public tags$: Observable<ITag[]>;

    // all tags
    public tags: ITag[];
    public tagsMap: Map<number, ITag>;
    private readonly tagsSource = new BehaviorSubject<ITag[]>([]);

    public constructor() {
        this.tags$ = this.tagsSource.asObservable();
        this.tags$.subscribe((tags) => {
            this.tags = tags.sort((a, b) => (a.delta > b.delta ? 1 : -1));
            const visibleTags = this.tags.filter((tag: ITag) => {
                return tag.visibility;
            });

            this.tagsMap = getTagsMap(visibleTags);
        });
    }

    public addTags(tags: ITag[]): void {
        this.tagsSource.next([...this.tagsSource.value, ...tags]);
    }

    public setTags(tags: ITag[]): void {
        this.tagsSource.next([...tags]);
    }

    public getTag(tagId: number): ITag | undefined {
        return this.tagsMap.get(tagId);
    }

    public clear() {
        this.tags.forEach((tag: ITag) => {
            if (tag.active != null) {
                tag.active = false;
            }
        });
    }
}

function getTagsMap(tags: ITag[]): Map<number, ITag> {
    const tagsMap = new Map(tags.map<[number, ITag]>((tag) => [tag.id, tag]));

    return tagsMap;
}
