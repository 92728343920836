import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";

import { IdleService } from "@core/idle/idle.service";

import { GameRepository, TagRepository, VideoRepository } from "@api-binding";
import { GameService, NavigationService, TagService, VideoService } from "@models";

@Component({
    selector: "mv-toothbrush",
    styleUrls: ["./app.component.scss"],
    templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
    public constructor(
        private readonly tagRepository: TagRepository,
        public readonly tagService: TagService,
        private readonly gameRepository: GameRepository,
        public readonly gameService: GameService,
        private readonly videoRepository: VideoRepository,
        public readonly videoService: VideoService,
        public readonly idleService: IdleService,
        public readonly navigationService: NavigationService,
    ) {
        this.idleService.shouldClear$.subscribe((clearState: boolean) => {
            if (clearState) {
                this.videoService.clear();
                this.tagService.clear();
                this.navigationService.goToHome();
            }
        });
    }

    public ngOnInit(): void {
        this.getVideos();
        this.getTags();
        this.getGames();
    }

    public getVideos() {
        this.videoRepository
            .getVideos()
            .pipe(take(1))
            .subscribe((videos) => {
                this.videoService.setVideos(videos);
                this.videoService.setFilteredVideos(videos);
            });
    }

    public getTags() {
        this.tagRepository
            .getTags()
            .pipe(take(1))
            .subscribe((tags) => this.tagService.setTags(tags));
    }

    public getGames() {
        this.gameRepository
            .getGames()
            .pipe(take(1))
            .subscribe((games) => this.gameService.setGames(games));
    }
}
