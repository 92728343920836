import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";

import { ITourProduct } from "@ui/ui-tour/src/tour-product";

import { OqpRepository, TourRepository } from "@api-binding/index";

@Injectable({
    providedIn: "root",
})
export class TourService implements OnDestroy {
    public currentTour$: Observable<ITourProduct | null>;
    public tourSelected = false;

    private readonly currentTourSource = new BehaviorSubject<ITourProduct | null>(null);

    private readonly subscriptions = new Subscription();

    public constructor(
        private readonly tourRepository: TourRepository,
        private readonly oqpRepository: OqpRepository,
    ) {
        this.currentTour$ = this.currentTourSource.asObservable();
        this.subscriptions.add(
            this.currentTour$.subscribe((tour) => (this.tourSelected = tour != null ? true : false)),
        );
    }

    public setCurrentTour(tour: ITourProduct | null): void {
        this.currentTourSource.next(tour);
    }

    public ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
