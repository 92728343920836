import { Injectable } from "@angular/core";

import { IGameGetDto } from "@dtos";
import { IGame } from "@models";

@Injectable()
export class GameMapper {
    public mapGetGame = (gameDto: IGameGetDto): IGame => {
        return { ...gameDto };
    };
}
