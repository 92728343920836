import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AppRoutes } from "./routes";

const routes: Routes = [
    {
        loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
        path: AppRoutes.HOME,
    },
    {
        loadChildren: () => import("./oqp/oqp.module").then((m) => m.OqpModule),
        path: AppRoutes.OQP,
    },
    {
        loadChildren: () => import("./game/game.module").then((m) => m.GameModule),
        path: AppRoutes.GAME,
    },
    {
        loadChildren: () => import("./multimedia/multimedia.module").then((m) => m.MultimediaModule),
        path: AppRoutes.VIDEO,
    },
    {
        path: "**",
        redirectTo: AppRoutes.HOME,
    },
    {
        path: "",
        pathMatch: "full",
        redirectTo: AppRoutes.HOME,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes, { useHash: true })],
})
export class AppRoutingModule {}
