import { AfterViewInit, Component, Input, OnDestroy, TrackByFunction } from "@angular/core";
import { Observable, Subscription } from "rxjs";

import { ITag, IVideo } from "@models";

import { environment } from "@environment";

@Component({
    selector: "fumanbrush-video",
    styleUrls: ["./video.component.scss"],
    templateUrl: "./video.component.html",
})
export class VideoComponent implements AfterViewInit, OnDestroy {
    @Input() public item: IVideo;
    @Input() public current$: Observable<IVideo | null>;
    @Input() public overlay = `red`;

    public thumbnailPath = `${environment.moustacheApiUrl}/${environment.mediaAssetsPathOptimized}/`;

    public shouldDisplayPlay: boolean = false;

    private readonly subscriptions = new Subscription();

    public ngAfterViewInit() {
        if (this.current$ != null) {
            this.subscriptions.add(
                this.current$.subscribe((video: IVideo) => {
                    setTimeout(() => {
                        this.shouldDisplayPlay = false;
                        if (video != null) {
                            if (video.id === this.item.id) {
                                // auto tag video has been watch
                                this.shouldDisplayPlay = true;
                                this.item.watched = true;
                            }
                        }
                    }, 100);
                }),
            );
        }
    }

    public trackById: TrackByFunction<ITag> = (index: number, tag: ITag): any => {
        return tag.id;
    };

    public ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
