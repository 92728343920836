import { Component, TrackByFunction } from "@angular/core";

import { PlayerService } from "../player.service";

import { IVideo } from "@models";

@Component({
    selector: "fumanbrush-player",
    styleUrls: ["./player.component.scss"],
    templateUrl: "./player.component.html",
})
export class PlayerComponent {
    public currentVideo: IVideo | null;

    public constructor(public readonly playerService: PlayerService) {
        this.playerService.currentVideo$.subscribe((video) => {
            this.currentVideo = video;
            if (this.currentVideo != null) {
                const currentPlayedVideo = document.getElementById(`${this.currentVideo.id}`);
                if (currentPlayedVideo != null) {
                    currentPlayedVideo.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
                }
            }
        });
    }

    public togglePlay() {
        if (this.playerService.playerState) {
            this.playerService.pauseVideo();
        } else {
            this.playerService.playVideo();
        }
    }

    public toggleFullscreen() {
        this.playerService.toggleFullscreen();
    }

    public toggleDetails() {
        this.playerService.toggleDetails();
    }

    public decreaseVolume() {
        this.playerService.decreaseVolume();
    }

    public increaseVolume() {
        this.playerService.increaseVolume();
    }

    public playPrev() {
        this.playerService.playPrevious();
    }

    public playVideo(video: IVideo) {
        this.playerService.changePlay(video);
    }

    public playNext() {
        this.playerService.playNext();
    }

    public seekTo(event: any) {
        const fullPos = event.target.parentElement.offsetWidth;
        const seekPos = event.offsetX;

        this.playerService.setProgressBarTo((seekPos * 100) / fullPos);
    }

    public trackById: TrackByFunction<IVideo> = (index: number, video: IVideo): any => {
        return video.id;
    };

    public close() {
        this.playerService.hide();
    }
}
