import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { IGame } from "./game";

@Injectable({
    providedIn: "root",
})
export class GameService {
    public games$: Observable<IGame[]>;

    // all games
    public games: IGame[];
    public gamesMap: Map<number, IGame>;
    private readonly gamesSource = new BehaviorSubject<IGame[]>([]);

    public constructor() {
        this.games$ = this.gamesSource.asObservable();
        this.games$.subscribe((games) => {
            this.games = games;
            this.gamesMap = getGamesMap(games);
        });
    }

    public setGames(games: IGame[]): void {
        this.gamesSource.next([...games]);
    }

    public isNotExternal(link: string) {
        const pattern = /^((http|https|ftp):\/\/)/;

        if (!pattern.test(link)) {
            return true;
        }

        return false;
    }
}

function getGamesMap(games: IGame[]): Map<number, IGame> {
    return new Map(games.map((game) => [game.id, game]));
}
