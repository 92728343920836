<div
    class="video-thumbnail {{ overlay }}-overlay"
    [ngStyle]="{ background: 'url(' + thumbnailPath + item.thumbnail + ')' }"
>
    <div class="video-thumbnail-trigger" id="{{ item.id }}"></div>
    <ng-container *ngIf="current$ | async as currentPlayerVideo">
        <div *ngIf="currentPlayerVideo && currentPlayerVideo.id === item.id" class="overlay-play">
            <span>Lecture en cours...</span>
        </div>
    </ng-container>
    <div *ngIf="shouldDisplayPlay === false && item.watched === true" class="overlay-play">
        <span>Déjà visionnée</span>
    </div>
    <div class="desc">
        <div class="title">{{ item.title }}</div>
        <div class="subtitle">{{ item.description }}</div>
    </div>
</div>
