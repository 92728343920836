import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class PopupService {
    public display$: Observable<boolean>;
    public popupAction$: Observable<boolean | null>;

    private readonly displaySource = new BehaviorSubject<boolean>(false);
    private readonly popupActionSource = new Subject<boolean>();

    public constructor() {
        this.display$ = this.displaySource.asObservable();
        this.popupAction$ = this.popupActionSource.asObservable();
    }

    public show() {
        this.displaySource.next(true);
    }

    public hide() {
        this.displaySource.next(false);
    }

    public confirmAction() {
        this.popupActionSource.next(true);
        this.hide();
    }

    public cancelAction() {
        this.popupActionSource.next(false);
        this.hide();
    }
}
