import { Component } from "@angular/core";

import { PopupService } from "../popup.service";

@Component({
    selector: "fumanbrush-popup",
    styleUrls: ["./popup.component.scss"],
    templateUrl: "./popup.component.html",
})
export class PopupComponent {
    public constructor(public readonly popupService: PopupService) {}

    public cancelAction() {
        this.popupService.cancelAction();
    }

    public confirmAction() {
        this.popupService.confirmAction();
    }
}
