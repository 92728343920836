import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SharedModule } from "@shared/shared.module";
import { IframeComponent } from "./iframe/iframe.component";

@NgModule({
    declarations: [IframeComponent],
    exports: [IframeComponent],
    imports: [CommonModule, SharedModule],
})
export class IframeModule {}
