import { Injectable } from "@angular/core";

import { Router } from "@angular/router";
import { AppRoutes } from "@app/routes";

const SECTION = {
    DISCOVER: "Comptoir des saveurs",
    GAME: "Jeux",
    OQP: "Où partir",
    VIDEO: "Vidéos",
};

@Injectable({
    providedIn: "root",
})
export class NavigationService {
    public constructor(private readonly router: Router) {}

    public goToSection(section: string) {
        switch (section) {
            case SECTION.OQP:
            default:
                this.goToOqp();
                break;

            case SECTION.VIDEO:
                this.goToVideo();
                break;

            case SECTION.GAME:
                this.goToGame();
                break;
        }
    }

    public goToOqp() {
        this.router.navigate([AppRoutes.OQP]).catch((error) => {
            console.error(`OQP - routing error : ${error.message}`);
        });
    }

    public goToGame() {
        this.router.navigate([AppRoutes.GAME]).catch((error) => {
            console.error(`GAME - routing error : ${error.message}`);
        });
    }

    public goToVideo() {
        this.router.navigate([AppRoutes.VIDEO]).catch((error) => {
            console.error(`VIDEO - routing error : ${error.message}`);
        });
    }

    public goToHome() {
        this.router.navigate([AppRoutes.HOME]).catch((error) => {
            console.error(`Home - routing error : ${error.message}`);
        });
    }
}
