import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from "@angular/core";
import { BehaviorSubject, interval, Observable, Subscription } from "rxjs";
import { takeWhile } from "rxjs/operators";

import { environment } from "@environment";

@Injectable({ providedIn: "root" })
export class IdleService implements OnDestroy {
    public shouldClear$: Observable<boolean>;
    public shouldClearSource = new BehaviorSubject<boolean>(false);

    protected onPause = false;

    // properties to manage screensaver
    private readonly renderer: Renderer2;
    private lastInteraction: Date = new Date();
    private definedInactivityPeriod = 10000;

    // list on user interaction
    private readonly subscriptions = new Subscription();
    private sessionsLive = false;

    // handle ui-kit screensaver
    private screensaver: HTMLElement | any;

    public constructor(private readonly rendererFactory2: RendererFactory2) {
        this.shouldClear$ = this.shouldClearSource.asObservable();
        this.renderer = this.rendererFactory2.createRenderer(null, null);
        // if any interaction computer or touch screen
        this.renderer.listen("document", "mousemove", (evt) => this.resetSession());
        this.renderer.listen("document", "touchstart", (evt) => this.resetSession());
    }

    // set screensaver launch time
    public setInactivityPeriod(min: number) {
        this.definedInactivityPeriod = min * 60 * 1000;
    }

    // init from toothbrush
    public initIdleForToothbrush() {
        // if screensaver from ui-kit exist
        if (document.querySelector("mv-screensaver") != null && this.screensaver == null) {
            this.screensaver = document.querySelector("mv-screensaver");
            // listen when it's hide and start a new session
            this.screensaver.addEventListener("screensaver-hide", (e: any) => {
                this.startSession();
                // for another ticket
                // console.log(e.detail); probably should send time of sleep for stat
                this.shouldClearSource.next(true);
            });
        }
        this.setInactivityPeriod(environment.inatictivityTime);
        this.startSession();
    }

    // start a session and subscribe to timer
    public startSession() {
        if (!this.sessionsLive) {
            this.resetSession();
            this.subscriptions.add(this.idlePoll().subscribe());
        }
    }

    // everytime there is interaction just reset timer
    public resetSession() {
        this.lastInteraction = new Date();
    }

    // toogle Pause for screensaver
    public setPause(pause: boolean) {
        this.onPause = pause;
        // if pause is removed reset session just in case
        if (!this.onPause) {
            this.startSession();
        }
    }

    // timer manager
    public idlePoll() {
        return interval(1000).pipe(
            takeWhile(() => {
                // if timer reach show screensaver
                if (new Date().getTime() - this.lastInteraction.getTime() >= this.definedInactivityPeriod) {
                    this.sessionsLive = false;
                    this.showScreensaver();
                } else {
                    this.sessionsLive = true;
                }

                return new Date().getTime() - this.lastInteraction.getTime() < this.definedInactivityPeriod;
            }),
        );
    }

    // show ui-kit screensaver
    public showScreensaver() {
        if (this.screensaver != null && !this.onPause) {
            this.screensaver.show();
        }
    }

    public ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
