import { Component, Input } from "@angular/core";

@Component({
    selector: "fumanbrush-button",
    styleUrls: ["./button.component.scss"],
    templateUrl: "./button.component.html",
})
export class ButtonComponent {
    @Input() public type = `classic`;
    @Input() public title: string;
}
